import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../contactPage.css"


import girl4 from "../images/girls/4.png"
import girl26 from "../images/girls/26.png"
import girl23 from "../images/girls/23.png"
import girl5 from "../images/girls/5.png"

const ContactThanksPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お問い合わせ" />
      <section className="PageTitle">
        <div class="wrap">
          <img src={ girl4 } className="girls_img"/>
          <img src={ girl26 } className="girls_img"/>
          <div>
            <h1>[　Contact　]</h1>
            <h2>お問い合わせページ</h2>
          </div>
          <img src={ girl23 } className="girls_img"/>
          <img src={ girl5 } className="girls_img"/>
        </div>
        <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
      </section>
      <section className="ContactPage">
        <div className="wrap">

thanks

        </div>
      </section>
    </Layout>
  )
}

export default ContactThanksPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
